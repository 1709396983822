var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-result", {
    attrs: { status: "404", title: "404，抱歉，没有找到此页面，无法访问..." },
    scopedSlots: _vm._u([
      {
        key: "extra",
        fn: function () {
          return [
            _c(
              "a-button",
              {
                key: "console",
                attrs: { type: "primary" },
                on: { click: _vm.handleHistory },
              },
              [_vm._v("返回首页")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }