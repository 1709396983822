<template>
    <a-result status="404" title="404，抱歉，没有找到此页面，无法访问...">
        <template #extra>
            <a-button key="console" type="primary" @click="handleHistory">返回首页</a-button>
        </template>
    </a-result>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        handleHistory() {
            //指定跳转地址
            this.$router.replace('/')
            // window.history.go(-1)
        }
    }
}
</script>
